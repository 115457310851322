import {
  LandlordFields,
  LandlordEmailFields,
  LandlordFlaggedEmailWithoutResolversFields,
} from 'types/graphql'

export type LandlordEmailWithFlaggedEmail = LandlordEmailFields & {
  flaggedEmail?: LandlordFlaggedEmailWithoutResolversFields
}

export type LandlordWithFlaggedEmails = LandlordFields & {
  emails?: LandlordEmailWithFlaggedEmail[]
}

export const LandlordFragment = gql`
  fragment LandlordFields on Landlord {
    id
    csiRating
    emailCensored
    # Relationship resolvers
    name
    unprocessedEmailsCount
    totalEmailsCount
  }
`

export const LandlordEmailFragment = gql`
  fragment LandlordEmailFields on LandlordEmail {
    id
    csiRating
    receivedAt
    sentimentScores
    flaggedStatus
  }
`

export const LandlordFlaggedEmailWithoutResolversFragment = gql`
  fragment LandlordFlaggedEmailWithoutResolversFields on LandlordFlaggedEmailWithoutResolvers {
    id
    membershipId
    reviewedByMembershipId
    feedback
    reviewedDate
    createdAt
    originalSentiment
    adminFeedback
  }
`
